export const hybridPlatformMap = {
  twitch: 'twitch',
  zoom: 'zoom',
  'google meet': 'meet',
  youtube: 'youtube',
  'microsoft teams': 'teams',
  vimeo: 'vimeo',
  webex: 'webex',
  linkedin: 'linkedin'
};

export const getGetHybridPlatformSlug = (hybridPlatform?: keyof typeof hybridPlatformMap) => {
  if (!hybridPlatform) return undefined;
  return hybridPlatformMap[hybridPlatform] as keyof typeof PLATFORMS;
};

export const PLATFORMS = {
  all: {
    order: 0,
    title: 'All',
    slug: 'all',
    logo: '/images/all_platforms.svg',
  },
  hybrid: {
    logo: '/images/hybrid.svg',
    title: 'Hybrid',
    order: 8,
    slug: 'hybrid',
  },
  twitch: {
    logo: '/images/twitch-icon.svg',
    title: 'Twitch',
    order: 6,
    slug: 'twitch',
  },
  zoom: {
    logo: '/images/Zoom.svg',
    title: 'Zoom',
    order: 1,
    slug: 'zoom',
  },
  ipa: {
    logo: '/images/in_person.svg',
    title: 'In-person',
    order: 7,
    slug: 'ipa',
  },
  meet: {
    logo: '/images/meet-icon.svg',
    title: 'Google Meet',
    order: 2,
    slug: 'meet',
  },
  youtube: {
    logo: '/images/youtube-icon.svg',
    title: 'YouTube',
    order: 3,
    slug: 'youtube',
  },
  teams: {
    logo: '/images/teams-logo.svg',
    title: 'Microsoft Teams',
    order: 4,
    slug: 'teams',
  },
  vimeo: {
    logo: '/images/vimeo-icon.svg',
    title: 'Vimeo',
    order: 5,
    slug: 'vimeo',
  },
  webex: {
    logo: '/images/webex-icon.svg',
    title: 'Webex',
    order: 10,
    slug: 'webex',
  },
  'other-online-events': {
    logo: '/images/OtherOnlinePlatforms.svg',
    title: 'Other online platforms',
    order: 9,
    slug: 'other-online-events',
  },
  linkedin: {
    logo: '/images/linkedin-icon.svg',
    title: 'LinkedIn',
    order: 10,
    slug: 'linkedin',
  },
};
