import { type interactionsMap } from '@components/Presentation/ai';
import { deleteWithAuth, getWithAuth, getWithOutAuth, postWithAuth } from '@services/http.service';
import { UrlParamsReplace } from '@services/url.service';

interface GenerateSlidesParams {
  count?: number;
  topic: string;
  audience: string;
  page?: number;
}

export const GENERATE_SLIDES = () => UrlParamsReplace('generate-slides');

export const GENERATE_SLIDES_SANDBOX_WEBSITE = () => UrlParamsReplace('generate-slides/sandbox-website');

export const generateSlides = ({
  count = 1,
  topic,
  audience,
  page = 1,
}: GenerateSlidesParams) =>
  ['sandbox'].includes(process.env.NEXT_PUBLIC_ENV_URL || '') ? postWithAuth(GENERATE_SLIDES_SANDBOX_WEBSITE(), { topic, audience, count, page }) : postWithAuth(GENERATE_SLIDES(), { topic, audience, count, page });

export const GET_AUTO_COMPLETE = (topic: string, type: 'presentation' | keyof typeof interactionsMap = 'presentation') =>
  UrlParamsReplace('gen_ai_search_history/autocomplete?topic=:topic&type=:type', {
    topic,
    type
  });

export const getAutoComplete = (topic: string, type?: 'presentation' | keyof typeof interactionsMap) =>
  getWithAuth(GET_AUTO_COMPLETE(topic, type));

export const GET_GEN_AI_TOPICS = () =>
  UrlParamsReplace('gen_ai_default_topics');

export const GET_GEN_AI_TOPICS_SANDBOX_WEBSITE = () =>
  UrlParamsReplace('gen_ai_default_topics/sandbox-website');

export const getDefaultTopics = () => ['sandbox', 'development'].includes(process.env.NEXT_PUBLIC_ENV_URL || '') ? getWithOutAuth(GET_GEN_AI_TOPICS_SANDBOX_WEBSITE()) : getWithAuth(GET_GEN_AI_TOPICS());

export const GET_GEN_AI_DEFAULT_AUDIENCE = () => UrlParamsReplace('gen_ai_default_audience');

export const GET_GEN_AI_DEFAULT_AUDIENCE_SANDBOX_WEBSITE = () => UrlParamsReplace('gen_ai_default_audience/sandbox-website');

export const getGenAiDefaultAudience = () => ['sandbox', 'development'].includes(process.env.NEXT_PUBLIC_ENV_URL || '') ? getWithOutAuth(GET_GEN_AI_DEFAULT_AUDIENCE_SANDBOX_WEBSITE()) : getWithAuth(GET_GEN_AI_DEFAULT_AUDIENCE());

export const DELETE_AUTO_COMPLETE = (topic: string) => UrlParamsReplace('gen_ai_search_history/autocomplete?topic=:topic', { topic });

export const deleteAutoComplete = (topic: string) => {
  return deleteWithAuth(DELETE_AUTO_COMPLETE(topic));
};

export const GENERATE_RESPONSE = (question: string, interaction: string) => UrlParamsReplace('generate-response?question=:question&interaction=:interaction', { question, interaction });

export const generateResponse = (question: string, interaction: string) => {
  return getWithOutAuth(GENERATE_RESPONSE(question, interaction));
};
