import { useEffect, useState, useRef } from 'react';
import { getRandomInt } from '@lib/random';
import { Progress } from '@nextui-org/progress';

const aiLoadingMessages = [
  'Cooking 🧑‍🍳 up engaging prompts for you',
  'Simulating 💬 human responses to these questions',
  'Sequencing 🎞️ interactive StreamAlive interactions.',
  'Serving up something 🍱 delicious soon',
  'Crafting 🎨 your StreamAlive masterpiece...',
  'Whispering 🤫 prompts to our AI muse...',
  'Brewing 🧪 a potion of engaging responses...',
  'Loading... ⏳ AI magic in progress...',
  'Setting the stage 🎭 for StreamAlive interactions...',
  'Warming up 🎙️ the mic for your AI co-star...',
  'Rehearsing 🎤 your AI-generated dialogue...',
  'Summoning 🪄 the spirit of conversation...',
  'Your StreamAlive adventure awaits... ✨',
  'Polishing 💎 your AI-generated gems...',
  'Curating 📖 the perfect AI response…',
  'Fine-tuning 🎻 your interactive symphony...'
];

const AILoader = () => {
  const arrayOfMessages = aiLoadingMessages;
  const progress = useRef(25);

  const [index, setIndex] = useState(getRandomInt(0, arrayOfMessages.length));

  const getRandomIndex = () => {
    const randIdx = getRandomInt(0, arrayOfMessages.length);
    setIndex((prev) => {
      if (prev === randIdx) {
        return (randIdx + 1) % arrayOfMessages.length;
      }
      return randIdx;
    });
  };

  useEffect(() => {
    let indexInterval = setInterval(() => {
      getRandomIndex();

      if (progress.current <= 50) {
        progress.current += 25;
      }
    }, 5000);

    return () => {
      clearInterval(indexInterval);
    };
  }, []);

  return (
    <div className='bg-white w-screen h-screen z-50'>
      <div className='h-full w-full flex items-center justify-center'>
        <div>
          <div className='flex items-center justify-center'>
            <Progress disableAnimation className='max-w-[300px] min-w-[300px]' isStriped color='danger' aria-label="Loading..." value={progress.current} />
          </div>
          <p className='text-grey-900 font-normal text-base mt-6 text-center'>
            {arrayOfMessages[index]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AILoader;
