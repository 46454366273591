import {
  useRef,
  useState,
  useEffect
} from 'react';
import Image from '@atoms/Image';
import * as yup from 'yup';
import db from '@services/firebase-service';
import { yupValidator } from '@lib/yup-validator';
import {
  query,
  addDoc,
  orderBy,
  onSnapshot,
  collection,
  limitToLast
} from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { pushDataLayerForEvent } from '@lib/gtag';
import { stringToColor } from '../Youtube/ChatBox';
import { Pulse } from '@atoms/Pulse';
import { useHotspot } from '@lib/hooks/useHotSpot';

const ChatBox = ({ streamId, featureId, interactionType, showInputPulse }) => {
  const { isHotspotEnabled, handleHotspotClose } = useHotspot('sandbox:chat-input:hotspot');
  const [messages, setMessages] = useState([]);
  const userName = 'You';
  const activeUserId = 'user';
  const featureType = useRef(null);

  useEffect(() => {
    featureType.current = interactionType;
  }, [interactionType]);

  // eslint-disable-next-line camelcase
  const onComment = async ({ chat_input }) => {
    const rawMessagesRef = collection(db, 'streams', streamId, 'rawMessages');

    const document = {
      upvotes: 0,
      // eslint-disable-next-line camelcase
      message: chat_input,
      time: Date.now(),
      userId: activeUserId,
      name: userName,
      interaction: interactionType
    };

    setMessages((prev) => [...prev, document]);

    await addDoc(rawMessagesRef, document);
  };

  useEffect(() => {
    if (!streamId) return undefined;

    const unSubMessages = onSnapshot(
      query(
        collection(db, 'streams', streamId, 'processedMessages'),
        orderBy('time'),
        limitToLast(20)
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const document = {
              ...change.doc.data(),
              id: change.doc.id
            };

            if (
              document?.name === userName
              && document?.userId === activeUserId
            ) { return; }

            if (featureType.current !== document?.interaction) return;

            setMessages((prev) => [...prev, document]);
          }
        });
      }
    );

    return () => {
      unSubMessages();
    };
  }, [streamId]);

  const {
    register, handleSubmit, setFocus, reset
  } = useForm({
    mode: 'onChange',
    resolver: yupValidator(
      yup.object().shape({
        chat_input: yup.string().required()
      })
    )
  });

  useEffect(() => {
    setFocus('chat_input');
  }, []);

  const handleComment = (data) => {
    pushDataLayerForEvent('sandbox_twitch_chatbox_send_message', { streamId }, false);
    onComment(data);
    reset({ chat_input: '' });
    setFocus('chat_input');
  };
  const elementRef = useRef();

  useEffect(() => {
    if (!messages.length) return;
    elementRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    setMessages([]);
  }, [featureId]);

  return (<div className='w-full flex flex-col'>
    <div
      className='p-2 border-b w-full'
      style={{
        borderColor: '#DCDCDC'
      }}
    >
      <Image
        style={{
          width: '100%'
        }}
        src="/images/sandbox/sandbox-twitch-chat-header.png" alt={'down-arrow'} />
    </div>

    <div className='p-4 flex-1 overflow-scroll'>
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
      <div style={{ float: 'left', clear: 'both' }} ref={elementRef}></div>

    </div>

    <div className='p-2'>
      <div style={{
        background: '#F1F3F4',
        filter: 'blur(0.6px)',
        borderColor: '#D8D8DD'
      }} className='flex items-center px-2 py-2 border-t border-l border-r rounded-t-lg'>
        <Image width="12" height="10" src="/images/sandbox/sandbox-twitch-info-icon.png" alt={'zoom-chatbox-footer-icon-1'} />
        <p style={{
          fontSize: '10px',
          color: '#6F6A6A',
          lineHeight: '12px'
        }} className="font-semibold">Followers-Only Chat</p>
      </div>

      <form
        onSubmit={handleSubmit(handleComment)}>
        <div className='border rounded-lg' style={{ borderColor: '#808084' }}>
          <div className='relative'>

            <input
              autoComplete='off'
              {...register('chat_input')}
              style={{ color: '#737487', fontSize: '10px', border: 'none' }}
              className="w-full h-6 font-medium  text-black md:leading-5 px-3"
              placeholder='Send a message'
            />
            {showInputPulse && isHotspotEnabled && (
              <div className='absolute -top-3 left-28'>
                <Pulse
                  isEnabled={isHotspotEnabled}
                  onClose={handleHotspotClose}
                  tooltip='Please type your response here; it will reflect in the interaction.'
                />
              </div>
            )}
          </div>
        </div>

        <input type="submit" hidden />

        <div className='flex items-center justify-end p-2'>
          <Image width="16" height="16" className="cursor-not-allowed mr-2" src="/images/sandbox/sandbox-twitch-chat-bottom-icon.png" alt={'zoom-chatbox-footer-icons'} />

          <button type='submit' className='btn text-white rounded-lg px-3 py-2 font-semibold text-xs' style={{
            background: '#8653F6'
          }}>
            Chat
          </button>
        </div>
      </form>
    </div>
  </div>
  );
};

const Message = ({ message }) => (
  <div className='mb-2'>
    <p style={{
      fontSize: '14px',
      lineHeight: '18px'
    }} className='font-normal '>
      <span style={{
        color: stringToColor(message.name)
      }} className='mr-1'>{message.name}</span>
      <span style={{
        wordWrap: 'anywhere'
      }}>{message.message}</span>
    </p>
  </div>
);

export default ChatBox;
