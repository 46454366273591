import Image from '@atoms/Image';
import ChatBox from '@components/Sandbox/Teams/ChatBox';
import { getOnFeature } from '@services/feature-service';
import EngagementMeter from '@components/EngagementMeter';
import NextPrevCapsule from '@components/RunOfShow/NextPrevCapsule';
import InteractionTitle from '@components/Sandbox/Title';
import { useHotspot } from '@lib/hooks/useHotSpot';
import Notification from '@components/Notification';
import { platformName } from '@services/utils.service';
import { useEffect, useState } from 'react';
import { Spinner } from '@atoms/new/spinner';
import NextImage from 'next/image';
import useGetImageProperties from '@components/Presentation/hooks/useGetImageProperties';
import { getColorsFromInteraction } from './sandbox.helper';

const TeamsSandbox = ({
  feature,
  stream,
  stopFeature,
  currentRosResult,
  next,
  linkQuestions,
  currentIndex,
  featureNextState,
  featurePrevState,
  prevRef,
  nextRef,
  isAiSandbox = false,
  isGeneratingResponses = false
}) => {
  const { isHotspotEnabled, handleHotspotClose } = useHotspot('sandbox:next/prev:button:hotspot');

  const isSlide = feature?.type === 'PDF Slide';

  const disableNotificationFor = (type = '') => ['PDF Slide', 'wheel', 'newWheel'].includes(type);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (!feature) return;
    setShowNotification(true);
  }, [feature]);

  // get background image settings
  const { getImageProperties } = useGetImageProperties();
  const {
    src: bgImgSrc,
    opacity: bgImgOpacity = 100,
  } = getImageProperties(feature?.type, feature?.setting);

  const { bgColor = '#111111', headingColor = '#FFFFFF' } = getColorsFromInteraction(feature?.type, feature?.color);

  return (
    <div className="flex rounded-lg border border-grey-200 max-h-[720px]" style={{ background: '#202124', height: '80vh' }}>
      <Image
        className='h-full cursor-not-allowed blur-xs rounded-lg'
        src="/images/sandbox/teams-left-app-bar.png"
        alt={'teams-top-app-bar'}
      />

      <div
        style={{ background: bgColor }}
        className=" w-full rounded-lg flex flex-col justify-between relative">
        {bgImgSrc && <NextImage
          src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${bgImgSrc}`}
          fill
          style={{ opacity: bgImgOpacity / 100 }}
          alt='background img'
        />}
        <Image
          style={{ height: '70px' }}
          className="w-full cursor-not-allowed blur-xs rounded-lg z-[2]"
          src="/images/sandbox/teams-top-app-bar.png"
          alt={'teams-top-app-bar'}
        />

        <div id="teams-container" className='flex z-[2]'>
          <div className='reltive flex flex-col w-4/5'>
            {isGeneratingResponses && (
              <div className='w-full h-full absolute z-50 flex items-center justify-center bg-[#D9D9D980]/50 top-0 left-0 bottom-6 right-0'>
                <Spinner />
              </div>
            )}
            {!isSlide && (
              <div className='rounded-tl-lg rounded-tr-lg flex items-center' style={{ height: '100px' }}>
                <div className="pt-4 pb-3 pl-2 w-full">
                  <InteractionTitle title={feature?.title}
                    type={feature?.type}
                    isLogoRequired={false}
                    logoUrl={'/images/logos/logo_white.svg'}
                    color={headingColor}
                  />
                </div>
              </div>
            )}
            <div
              id="hero"
              className='flex-1 p-2 pl-17 h-3/4 relative'
            >
              {showNotification && !disableNotificationFor(feature?.type) && <Notification platformName={platformName.teams} />}

              {feature && getOnFeature({
                data: [],
                setShowNotification,
                streamId: stream.id,
                interactionType: {
                  isResult: false,
                  isRos: true
                },
                setShowRosModel: () => { },
                setCurrentFeatureId: () => { },
                dispatchTab: () => { },
                setInteractionState: () => { },
                setShowToast: () => { },
                stopFeature,
                currentRosResult,
                startInteraction: true,
                currentRosFeature: feature,
                platformType: stream?.platform_type,
                streamName: stream?.title,
                moderationModeRef: { current: null },
                activeInteractionRef: { current: null },
                meta: null,
                updateFSMeta: () => { },
                pushToFS: () => { },
                pushCmdToFS: () => { },
                isSandbox: true,
                setStartTime: () => {}
              })}
            </div>
            <div className='h-18 flex items-center px-2 py-2 dark:bg-neutral-80'>
              {!!linkQuestions && (
                <div className='relative items-center justify-center pl-15'>
                  {isAiSandbox && currentIndex === 0 && (
                    <img
                      src='/images/sandbox/click-here.png'
                      className='absolute bottom-[60px] left-[60px] w-32'
                    />
                  )}
                  <NextPrevCapsule
                    {...{
                      next,
                      pulseProps: {
                        tooltip: 'Click here to proceed to the next slide',
                        isEnabled: isHotspotEnabled,
                        onClose: handleHotspotClose
                      },
                      showTotal: true,
                      linkQuestions,
                      currentIndex,
                      featureNextState,
                      featurePrevState,
                      prevRef,
                      nextRef,
                      streamIndex: 0,
                      isTrack: false
                    }}
                  />
                </div>
              )}

              {stream?.id && <div className='relative -rotate-90 -top-6 -left-52'>
                <EngagementMeter streamId={stream.id} id="engMeterCtdId" />
              </div>}
            </div>
          </div>
          <div className="relative flex w-1/5 bg-black rounded-lg">
            {isAiSandbox && currentIndex === 1 && (
              <img
                src='/images/sandbox/website/big-arrow.png'
                className='absolute bottom-[8%] right-[92%] z-20 w-64'
              />
            )}
            <ChatBox
              streamId={stream?.id}
              featureId={feature?.id}
              interactionType={feature?.type}
              showInputPulse={currentIndex >= 1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamsSandbox;
