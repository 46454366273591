import Image from '@atoms/Image';
import Typewriter from 'typewriter-effect';
import React, { useState, useEffect } from 'react';

const calculateDelay = (title) => {
  if (!title) return 'natural';

  const maxTimeInSeconds = 1.5;
  const delay = (maxTimeInSeconds * 1000) / title.length;
  return delay;
};

const Title = ({
  title,
  type,
  logoUrl,
  isPreview = false,
  isLogoRequired = false,
  color = '#FFFFFF'
}) => {
  const [fontSize, setFontSize] = useState('xl:text-3xl leading-10 lg:text-2xl leading-8');
  const [isRun, setIsRun] = useState(false);
  const [textColor, setTextColor] = useState(color);
  const [placeholderTitle, setPlaceholderTitle] = useState(null);

  useEffect(() => {
    /*
      TypeWriter has a bug where it doesn't re-render on text change.
      This timeout causes the TypeWriter to re-render
    */
    setPlaceholderTitle(null);
    setTimeout(() => {
      setPlaceholderTitle(title);
    }, 100);

    setTextColor(color);
  }, [title]);

  const size = {
    small: 'xl:text-2xl leading-8 lg:text-base leading-5',
    medium: 'xl:text-3xl leading-10 lg:text-lg leading-5',
    large: 'xl:text-4xl leading-10 lg:text-2xl leading-8'
  };

  const updateFontSize = () => {
    const { small, medium, large } = size;
    const element = document.getElementById('hidden');
    if (!element) return;
    const lineHeight = parseInt(getComputedStyle(element).lineHeight, 10);
    const elementHeight = element.scrollHeight;
    const lines = Math.round(elementHeight / lineHeight);
    if (lines === 1) {
      setFontSize(large);
    } else if (lines === 2) {
      setFontSize(medium);
    } else {
      setFontSize(small);
    }
  };

  if (!isRun) {
    updateFontSize();
    setIsRun(true);
  }

  useEffect(() => {
    setIsRun(false);
  }, [title]);

  if (!type) return null;

  const getTitle = () => {
    if (!placeholderTitle) return null;

    const delay = calculateDelay(placeholderTitle);
    if (type === 'wheel') {
      return placeholderTitle;
    }

    return <Typewriter
      options={{
        delay
      }}
      onInit={(typewriter) => {
        typewriter
          .typeString(placeholderTitle)
          .changeCursor(' ')
          .callFunction(() => {
            setTextColor(color);
          })
          .start();
      }} />;
  };

  return (
    <div className='w-9.9/10 h-full flex justify-center items-center gap-x-4' >
      <div className='flex flex-col flex-grow '>
        <p id='hidden' className='hidden-div xl:text-3xl xl:first:leading-10 lg:text-lg leading-5 text-neutral-60 dark:text-white font-Poppins font-normal  break-normal '>
          {title}
        </p>

        <div
          style={{ color: textColor }}
          id='visible' className={`${!isPreview ? fontSize : 'text-lg'}  dark:text-white font-Poppins font-normal  break-normal ${!isPreview ? 'line-clamp-3' : 'line-clamp-2'} `}>
          {getTitle()}
        </div>
      </div>
      {isLogoRequired && <Image className="inline-block max-w-brand-logo-sm max-h-brand-logo-sm flex-shrink-0 px-5" src={logoUrl} />}
    </div>
  );
};

export default Title;
