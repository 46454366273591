import { useEffect, useRef, useState } from 'react';
import { type InteractionType } from '@components/Presentation/types';
import { generateResponse } from '@services/generative-ai';

interface Question {
  title: string;
  type: InteractionType;
}

interface GeneratedResponse {
  index: number;
  user: string;
  message: string;
}

interface GeneratedResponses {
  question: string;
  type: InteractionType;
  response: GeneratedResponse[];
}

export const useGeneratedResponses = (questions?: Question[]) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<GeneratedResponses[] | null>(null);
  const prevLength = useRef(0);

  useEffect(() => {
    if (!questions) return;

    // Prevents the hook from running on rerenders
    if (prevLength.current !== 0) return;

    prevLength.current = questions.length;

    (async() => {
      try {
        setIsLoading(true);

        const response = await Promise.all(
          questions
            .filter((question) => ![
              'PDF Slide',
              'wheel',
              'newWheel',
              'textTrack',
              'fantasticFans'
            ].includes(question.type))
            .map((question) => generateResponse(question.title, question.type)));

        setData(
          response
            .map((res) => ({
              question: res.entity.question,
              response: res.entity.response,
              type: res.entity.type
            }))
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [questions]);

  return { data, isLoading };
};
